import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import RouteTracking from '@/router/RouteTracking'
import PrivateRoute from '@/router/PrivateRoute'
import { isNamespace } from '@/helpers/env'
import CatalogPage from '@/pages/CatalogPage'
import DashboardPage from '@/pages/DashboardPage'
// import EmailValidationPage from '@/pages/EmailValidationPage'
import ErrorPage from '@/pages/ErrorPage'
import InvitePage from '@/pages/InvitePage'
import CustomProjectsCreationPage from '@/pages/CustomProjectsCreationPage'
import ModulePage from '@/pages/ModulePage'
import AssessmentPage from '@/pages/AssessmentPage'
// import ModuleReportPage from '@/pages/ModuleReportPage'
import NotFoundPage from '@/pages/NotFoundPage'
import PlaygroundPage from '@/pages/PlaygroundPage'
import PlaygroundsPage from '@/pages/PlaygroundsPage'
import AuthPage from '@/pages/AuthPage'
import SkillTrackPage from '@/pages/SkillTrackPage'
import SessionExpiredImage from '@/assets/images/session-expired.png'

const getRoutes = (isDatawarsOrg) => {
  return [
    {
      element: <RouteTracking />,
      errorElement: <ErrorPage />,
      children: !isNamespace
        ? [
            {
              path: '/login',
              element: <AuthPage />,
            },
            {
              path: '/register',
              element: <AuthPage />,
            },
            {
              path: '/forgot-password',
              element: <AuthPage />,
            },
            {
              path: '/reset-password',
              element: <AuthPage />,
            },
            // {
            //   path: '/email-validation',
            //   element: <EmailValidationPage />,
            // },
            {
              path: '/project/:moduleId',
              element: <ModulePage />,
            },
            {
              element: <PrivateRoute />,
              children: [
                {
                  path: '/launch/:launchId',
                  element: <ModulePage />,
                },
                {
                  path: '/dashboard',
                  element: <DashboardPage />,
                },
                {
                  path: '/catalog',
                  element: <CatalogPage />,
                },
                {
                  path: '/skill-track/:skillTrackId',
                  element: <SkillTrackPage />,
                },
                {
                  path: '/playgrounds/:playgroundId',
                  element: <PlaygroundPage />,
                },
                {
                  path: '/playgrounds',
                  element: <PlaygroundsPage />,
                },
                {
                  path: '/custom-projects/:accountId/create',
                  element: <CustomProjectsCreationPage />,
                },
                {
                  path: '/custom-projects/:accountId/:playgroundId',
                  element: <PlaygroundPage />,
                },
                {
                  path: '/custom-projects/:accountId/:projectId/settings',
                  element: <CustomProjectsCreationPage />,
                },
                {
                  path: '/assessment/:moduleId',
                  element: <AssessmentPage />,
                },
                {
                  path: '/invite/:inviteId',
                  element: <InvitePage />,
                },
                // {
                //   path: '/project/:moduleId/report',
                //   element: <ModuleReportPage />,
                // },
              ],
            },
            {
              path: '/not-found',
              element: <NotFoundPage />,
            },
            {
              path: '*',
              element: <Navigate replace to={isDatawarsOrg ? '/dashboard' : '/not-found'} />,
            },
          ]
        : [
            {
              path: '/session-expired',
              element: (
                <NotFoundPage
                  title="Session expired"
                  text="For your security, your session has timed out. Please go to your LMS and log in again this content"
                  image={SessionExpiredImage}
                />
              ),
            },
            {
              element: <PrivateRoute />,
              children: [
                {
                  path: '/project/:moduleId',
                  element: <ModulePage />,
                },
                {
                  path: '/launch/:launchId',
                  element: <ModulePage />,
                },
              ],
            },
            {
              path: '*',
              element: <NotFoundPage />,
            },
          ],
    },
  ]
}

const Router = () => {
  const { userProfile } = useSelector((state) => state.users)
  const isDatawarsOrg = !userProfile?.organization || userProfile?.organization?.name === 'Datawars'

  const router = createBrowserRouter(getRoutes(isDatawarsOrg))

  return <RouterProvider router={router} />
}

export default Router
