import { isNamespace } from '@/helpers/env'

export const getDefaultUiConfig = (orgName) => {
  const isDatawarsOrg = orgName === 'Datawars'
  const defaultVisibility = isNamespace ? false : isDatawarsOrg || !orgName ? true : false

  return {
    organization: {
      id: null,
      name: null,
      domain: null,
      whitelabel_is_enabled: false,
      whitelabel_logo_image_url: null,
      whitelabel_logo_link_url: null,
      whitelabel_primary_color: null,
      whitelabel_secondary_color: null,
      config: {
        lock_navigation: false,
        launch_labs_requires_email_validated: false,
      },
    },
    // general
    showUpgradeToProUi: defaultVisibility,
    metaTitle: defaultVisibility ? 'DataWars' : null,
    // navbar
    showNavbarLogo: defaultVisibility,
    showNavbarLeftMenu: defaultVisibility,
    showNavbarRightMenu: defaultVisibility,
    showNavbarModuleNavigation: defaultVisibility,
    showNavbarStreakInfo: defaultVisibility,
    showNavbarGoToTeamsButton: defaultVisibility,
    // module page
    showModuleSkillTrack: defaultVisibility,
    showModuleDifficulty: defaultVisibility,
    showModuleAuthor: defaultVisibility,
    showModuleBoostLab: defaultVisibility,
    showModuleEarlyAccessUi: defaultVisibility,
    showModuleReportIssue: defaultVisibility,
    showModuleShareUi: defaultVisibility,
    showModuleRatingUi: defaultVisibility,
    showModuleNextUp: defaultVisibility,
    showModuleAiAssistant: defaultVisibility,
    showGoToRunningModule: defaultVisibility,
    showErrorCardActions: defaultVisibility,
    labErrorMessage: isNamespace ? (
      'If the problem persists, please contact your lab provider.'
    ) : (
      <>
        If the problem persists, please send us an email to:{' '}
        <a href="mailto:support@datawars.io?subject=Error while starting project">support@datawars.io</a>.
      </>
    ),
  }
}
