import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isNamespace } from '@/helpers/env'
import GlobalLoader from '@/components/GlobalLoader'
import UpgradeToProSuccessModal from '@/components/UpgradeToProSuccessModal'
import { setAppError, setAppNotification } from '@/store/app/actions'
import { tryAutoLogin, getUserStats } from '@/store/users/actions'
import Router from '@/router'
import Theme from '@/themes/base'
import { initSentry } from '@/utils/sentry'
import { initializeDayjs } from '@/utils/dayjs'

initSentry()
initializeDayjs()

const App = () => {
  const dispatch = useDispatch()
  const [isPageLoading, setPageLoading] = useState(true)

  const { uiConfig, appNotification, appError } = useSelector((state) => state.app)
  const { isAuthenticated } = useSelector((state) => state.users)

  const onLoad = useCallback(async () => {
    await dispatch(tryAutoLogin())
    setPageLoading(false)
  }, [])

  if (appError) {
    // toastError(appError)
    dispatch(setAppError(null))
  }

  if (appNotification) {
    // toastNotification(appNotification)
    dispatch(setAppNotification(null))
  }

  useEffect(() => {
    if (isAuthenticated === null) {
      onLoad()
      return
    }

    if (isAuthenticated && !isNamespace) {
      dispatch(getUserStats())
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const title = uiConfig?.metaTitle || (isNamespace ? 'Practice projects' : 'DataWars')
    const fullTitle = `${title} - Become an expert Data Scientist`
    const image = isNamespace
      ? 'https://static.prd.datawars.io/static/og-images/og-image-default.png'
      : 'https://static.prd.datawars.io/static/og-images/og-image-general.png'
    const url = isNamespace ? '' : 'https://app.datawars.app'

    document.title = title
    document.querySelector("meta[property='og:title'").setAttribute('content', fullTitle)
    document.querySelector("meta[property='og:image'").setAttribute('content', image)
    document.querySelector("meta[property='twitter:title'").setAttribute('content', fullTitle)
    document.querySelector("meta[property='twitter:image'").setAttribute('content', image)
    document.querySelector("meta[property='og:url'").setAttribute('content', url)
  }, [])

  return (
    <Theme>
      {isPageLoading ? <GlobalLoader /> : <Router />}

      <UpgradeToProSuccessModal />
    </Theme>
  )
}

export default App
