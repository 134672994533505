import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isNamespace } from '@/helpers/env'

const PrivateRoute = ({ children, ...props }) => {
  const { userProfile, isAuthenticated } = useSelector((state) => state.users)

  if (isAuthenticated === false) {
    const originalPath = window?.location?.href
    return (
      <Navigate
        to={isNamespace ? 'session-expired' : userProfile ? '/login' : `/login?redirect_to=${originalPath}`}
        replace
      />
    )
  }

  return children || <Outlet />
}

export default PrivateRoute
