import { useState } from 'react'
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import OverlappedLoader from '@/components/OverlappedLoader'
import AuthModal from '@/components/auth/AuthModal'
import EmailVerificationModal from '@/components/EmailVerificationModal'
import UpgradeToProModal from '@/components/UpgradeToProModal'
import ReportIssueModal from '@/components/ReportIssueModal'
import Navbar from './components/Navbar'
import { Layout, Content, MainContent, SecondaryContent, ChildrenContent } from './styles'

const Layout1 = ({
  navbar,
  mainContent,
  secondaryContent,
  footerContent,
  drawer,
  resizable,
  siderContent,
  disableSiderCollapse,
  isLoading,
  isFullScreen,
  children,
}) => {
  const [isSiderCollapsed, setSiderCollapsed] = useState(true)

  return (
    <Layout hasSider={false}>
      {navbar && <Navbar isLoading={isLoading} />}

      {isLoading && <OverlappedLoader />}

      <Content isfullscreen={String(isFullScreen)}>
        {siderContent && (
          <Layout.Sider
            className="sider"
            collapsible={!disableSiderCollapse}
            collapsedWidth={50}
            collapsed={isSiderCollapsed}
            onCollapse={(value) => setSiderCollapsed(value)}
          >
            {siderContent}
          </Layout.Sider>
        )}

        <ReflexContainer orientation="vertical">
          {mainContent && (
            <ReflexElement className="left-pane" minSize={400} flex={secondaryContent ? 0.4 : 1}>
              <MainContent className="main-content">{mainContent}</MainContent>
            </ReflexElement>
          )}

          {secondaryContent && resizable && <ReflexSplitter className="middle-splitter" />}

          {secondaryContent && (
            <ReflexElement className="right-pane" minSize={700} flex={0.6}>
              <SecondaryContent className="secondary-content">{secondaryContent}</SecondaryContent>
            </ReflexElement>
          )}

          {children && (
            <ReflexElement className="children-pane">
              <ChildrenContent className="children-content">{children}</ChildrenContent>
            </ReflexElement>
          )}
        </ReflexContainer>

        {drawer}
      </Content>

      {footerContent}

      <AuthModal />
      <EmailVerificationModal />
      <UpgradeToProModal />
      <ReportIssueModal />
    </Layout>
  )
}

export default Layout1
